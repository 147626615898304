
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
      <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom"
            label-for="basicInput"
          >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Photo"
            label-for="Photo"
          >
          
           <b-media vertical-align="top" v-if="file!==null">
                <template #aside>
                  <b-img
                    :src="file"
                    blank-color="#ccc"
                    width="64"
                    alt="placeholder"
                    
                  />
                </template>
                
                <b-card-text class="mb-0">
                  
                  <b-button
                  size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-warning"
                    class="btn-icon"
                    @click="editPhoto()"
                  >
                     <feather-icon icon="EditIcon" />
                  </b-button>
                </b-card-text>
              </b-media>
          <b-form-file v-else
          required
              v-model="file"
              :state="Boolean(file)"
              placeholder="Choisissez ou faites glisser un fichier..."
              drop-placeholder="Déposer ici ..."
              @change="fileAdded($event)"
             
          />
          
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Prix"
            label-for="Prix"
          >
           <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
            <b-form-input
            required
              id="Prix"
              v-model="form.price"
              :plaintext="readOnly"
              type="number"
              step="0.01"
              placeholder="Prix"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

            <b-form-group
              label="Stock"
              label-for="Stock"
            >
            <validation-provider
              #default="{ errors }"
              name="Stock"
              rules="required|integer"
            >
              <b-form-input
                id="Stock"
                v-model="form.stock"
                :plaintext="readOnly"
                type="number"
                step="1"
                :state="errors.length > 0 ? false:null"
                placeholder="Stock"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Couleur"
            label-for="Couleur"
          >
          <validation-provider
              #default="{ errors }"
              name="Couleur"
              rules="required"
            >
            <v-select
              
              v-model="form.color"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="colorChoices"
               :state="errors.length > 0 ? false:null"
            />
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

         <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Code"
            label-for="Code"
          >
          <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
            <b-form-input
            required
              id="Code"
              v-model="form.code"
              :plaintext="readOnly"
              :state="errors.length > 0 ? false:null"
              placeholder="#ffffff"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="ID Unity"
            label-for="Stock"
          >
          <validation-provider
              #default="{ errors }"
              name="Unity ID"
              rules="integer"
            >
            <b-form-input
              id="Stock"
              v-model="form.unity_id"
               :state="errors.length > 0 ? false:null"
              :plaintext="readOnly"
              type="number"
              step="1"
              placeholder="ID Unity"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Type de branche"
            label-for="Type de branche"
          >
          
            <v-select
              
              v-model="form.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="typeChoices"
              
            />
          
          </b-form-group>
        </b-col>

      </b-row>
        </b-form>
      </validation-observer>

    </b-card>

  <b-button v-if="edition==false"
     @click="createBranch" :disabled="invalid"
    >
    Enregistrer
    </b-button>

    <b-button v-else :disabled="invalid"
     @click="updateBranch"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BForm,  BRow, BCol,BOverlay, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'BranchForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BCardText, BMedia, BImg,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          colors:[],
          loading:true,
          colorChoices:[],
          typeChoices:[
            {
              value:'plastique',
              title:'Plastique',
            },
            {
              value:'metal',
              title:'Métal',
            }
          ],
          edition:false,
          form:{
            name:"",
            file:'',
            code:'',
            stock:0,
            price:0,
            photo:'',
            type:null,
            unity_id:null,
            color:null
          },
          validation: true,
          required
      }
  },
  props: ['branch', 'readOnly'],
  methods:{
    editPhoto() {
      this.file=null
    },
    async getColors() {
      let colors = await APIRequest.list('colors')
      this.colors=colors[0];
      this.colorChoices=Utils.formatSelect(this.colors,'name','id')
    },
    async createBranch()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        if(this.form.color!=null)
        {
          let color_id = this.form.color.value
          this.form.color={}
          this.form.color.id=color_id
        }
        if(this.form.type!=null)
        {
          let type = this.form.type.value
          this.form.type=type
        }
        try {
          
          await APIRequest.create('branches',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La branche a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'branches'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async updateBranch()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.loading=true
          if(this.form.color!=null)
          {
            let color_id = this.form.color.value
            this.form.color={}
            this.form.color.id=color_id
          }
          if(this.form.type!=null)
          {
            let type = this.form.type.value
            this.form.type=type
          }
          try {
            
            await APIRequest.update('branches',this.branch.id,this.form)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'La branche a bien été modifiée !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'branches'})
          } catch (error) {
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
      })
      
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        
          let b64=reader.result.split(';')
          let type=b64[0]
          if(type.includes('image'))
          {
              let base64=b64[1]
              //base64.replace('base64,','')
              this.currentBase64=reader.result
              this.form.photo=this.currentBase64
          }
          else
          {
              this.file=null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ceci n'est pas une image !",
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
          }
          
      };
    },
     
      
  },
  async mounted(){

    await this.getColors();
    if(this.branch!=null)
    {
      this.form=this.branch
      if(this.branch.color!=null)
      {
        for(let i=0;i<this.colorChoices.length;i++)
        {
          if(this.branch.color.id == this.colorChoices[i].value)
          {
            this.form.color = this.colorChoices[i]
          }
        }
      }
 
      this.file=this.branch.photo
      this.edition=true
      
    }
    this.loading=false
   }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>