var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',[_c('b-card',{attrs:{"title":"Informations générales"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"basicInput","plaintext":_vm.readOnly,"placeholder":"Nom","state":errors.length > 0 ? false:null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Photo","label-for":"Photo"}},[(_vm.file!==null)?_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{attrs:{"src":_vm.file,"blank-color":"#ccc","width":"64","alt":"placeholder"}})]},proxy:true}],null,false,2264326756)},[_c('b-card-text',{staticClass:"mb-0"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-warning"},on:{"click":function($event){return _vm.editPhoto()}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)],1):_c('b-form-file',{attrs:{"required":"","state":Boolean(_vm.file),"placeholder":"Choisissez ou faites glisser un fichier...","drop-placeholder":"Déposer ici ..."},on:{"change":function($event){return _vm.fileAdded($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Prix","label-for":"Prix"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Prix","plaintext":_vm.readOnly,"type":"number","step":"0.01","placeholder":"Prix","state":errors.length > 0 ? false:null},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Stock","label-for":"Stock"}},[_c('validation-provider',{attrs:{"name":"Stock","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Stock","plaintext":_vm.readOnly,"type":"number","step":"1","state":errors.length > 0 ? false:null,"placeholder":"Stock"},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Couleur","label-for":"Couleur"}},[_c('validation-provider',{attrs:{"name":"Couleur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.colorChoices,"state":errors.length > 0 ? false:null},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"#ffffff"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"ID Unity","label-for":"Stock"}},[_c('validation-provider',{attrs:{"name":"Unity ID","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Stock","state":errors.length > 0 ? false:null,"plaintext":_vm.readOnly,"type":"number","step":"1","placeholder":"ID Unity"},model:{value:(_vm.form.unity_id),callback:function ($$v) {_vm.$set(_vm.form, "unity_id", $$v)},expression:"form.unity_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Type de branche","label-for":"Type de branche"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.typeChoices},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1)],1)],1)],1)],1),(_vm.edition==false)?_c('b-button',{attrs:{"disabled":_vm.invalid},on:{"click":_vm.createBranch}},[_vm._v(" Enregistrer ")]):_c('b-button',{attrs:{"disabled":_vm.invalid},on:{"click":_vm.updateBranch}},[_vm._v(" Enregistrer ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }